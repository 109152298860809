import BlogFocus from '@/pages/site/home/components/BlogFocus.jsx';
import Newsletter from '@/pages/site/home/components/Newsletter.jsx';
import { createSiteLayout } from '@/pages/site/layouts/SiteLayout.jsx';

import Hero from './components/Hero.jsx';
import HomeFaq from './components/HomeFaq.jsx';
import HowTo from './components/HowTo.jsx';
import Trust from './components/Trust.jsx';
import Unlock from './components/Unlock.jsx';
import WhyGoldframer from './components/WhyGoldframer.jsx';
import WhyInvest from './components/WhyInvest.jsx';

export default function SiteHome() {
  return (
    <>
      <Hero />
      <WhyInvest />
      <WhyGoldframer />
      <Trust />
      <BlogFocus />
      <Unlock />
      <HowTo />
      <HomeFaq />
      <Newsletter />
    </>
  );
}

SiteHome.layout = page => createSiteLayout(page);
